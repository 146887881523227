<template lang="pug">
  .essays
    nav.fixed.overlay.flex.flex-col.lg_flex-row(v-if="doc")
      //- essay links...
      router-link.block.flex-1.p-8.lg_p-20(v-for="doc in essays", :to="{name: 'essay', params: {essay: doc.uid}}", :class="{'bg-white': doc.data.format === 'Scheme 2'}", :key="doc.uid")
        //- title
        .font-louize.text-2xl.lg_text-42.mb-5.lg_mb-10 {{ $prismic.asText(doc.data.title) }}
        //- byline
        .font-louize.text-mdd.lg_text-34.leading-normal.flex.flex-wrap.items-end
          rich-text.mr-5(:field="doc.data.lede", style="margin-bottom:0.5em")
          //- right arrows
          img.block.md_hidden(src="@/assets/icons/right-arrow-short--black.svg", style="width:calc(51/19*1em);height:calc(9/19*1em);margin-bottom:0.75em")
          img.hidden.md_block(src="@/assets/icons/right-arrow--black.svg", style="width:calc(113/34*1em);height:calc(16/34*1em);margin-bottom:0.8em")

    //- essay as child overlay
    transition(name="essayoverlay")
      router-view.fixed.overlay.z-10(@closeEssay="$router.push({name: 'essays'})")
</template>

<script>
import store from '@/store'
export default {
  name: 'Essays',
  computed: {
    doc () {
      return this.$store.state.docs.find(doc => doc.type === 'essays')
    },
    essays () {
      return this.doc.data.essays.map(item => this.$store.getters.essays.find(doc => doc.uid === item.essay?.uid))
    }
  },
  beforeRouteEnter (to, from, next) {
    store.commit('setUIColor', 'black')
    next()
  }
}
</script>

<style>
.inline-children *{
  display: inline
}
</style>
