<template lang="pug">
  //- position set on parent
  .essay-overlay.flex(:class="{'bg-vanilla': scheme === '1', 'bg-white': scheme === '2'}")
    //- close essay btn
    close-btn-x.absolute.z-20.top-0.right-0(@click="close")
    //- essay
    article.flex.w-full.bg-inherit(v-if="doc")
      //- scrolling layer
      section#essay.w-full.overflow-y-scroll
        .max-w-essay.p-8.md_p-20.lg_pb-96.relative(:class="{'lg_px-0 lg_pt-20': scheme === '2'}")
          slice-body(:slices="doc.data.body", :templates="templates", :scheme="scheme", @mediaclick="onMediaClick")

      //- lightbox
      essay-slideshow.z-10(ref="lightbox", :slices="doc.data.slides", :visible="lbox", @close="lbox = false", :scheme="scheme")
      //- (open lightbox button, mbl)
      footer-links.lg_hidden.absolute.bottom-0.left-0.bg-inherit.w-full.px-8.lg_px-20(v-if="hasSlides")
        button(@click="openLightbox", :class="{'text-red': scheme === '2'}") Visual Essay
</template>

<script>
import SliceEssayText from '@/slices/SliceEssayText'
import SliceEssayImage from '@/slices/SliceEssayImage'
import SliceEssayQuote from '@/slices/SliceEssayQuote'
export default {
  name: 'Essay',
  data () {
    return {
      templates: {
        text: SliceEssayText,
        image: SliceEssayImage,
        essay_quote: SliceEssayQuote
      },
      lbox: true
    }
  },
  computed: {
    doc () {
      return this.$store.getters.essays.find(doc => doc.uid === this.$route.params.essay)
    },
    scheme () {
      return this.doc?.data.format?.slice(-1)
    },
    hasSlides () {
      return this.doc?.data.slides.length
    }
  },
  methods: {
    close () {
      if (this.lbox) {
        this.lbox = false
        return
      }
      this.$emit('closeEssay')
    },
    onMediaClick (url) {
      if (this.hasSlides) {
        this.lbox = true
        this.$refs.lightbox.jumpTo({ url: url })
      }
    },
    openLightbox () {
      this.lbox = true
      this.$refs.lightbox.jumpTo({ index: 0 })
    }
  }
}
</script>

<style>
.parallax{
  perspective: -1px;
  /*height:100%; handled by flex*/
}
.parallax__layer--back {
  transform: translateZ(2px);
  /*transform-origin:center center;*/
}
</style>
